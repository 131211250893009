import PropTypes from 'prop-types'
import Link from 'next/link'
import { FormattedMessage } from 'react-intl'
import Bundle from 'components/Bundle'
import Tabs, { HeaderSet, Header, PanelSet, Panel } from 'components/tabs'
import { Muted } from 'components/text'
import Table from './Table'
import * as Columns from './columns'
import * as Headers from './headers'
import { useIntlMemo } from 'hooks/intl'
import ChartSet from './ChartSet'
import { path } from 'utils/station'
import Shim from 'components/Shim'

Station.propTypes = {
    measurements: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default function Station({ measurements }) {
    const columns = useColumns()
    const headers = useHeaders()

    if (measurements.length === 0) {
        const values = {
            anchor(chunks) {
                return <Link href={path()}>{chunks}</Link>
            },
        }

        return (
            <Shim horizontal>
                <Muted>
                    <FormattedMessage
                        description="Component weather/station/Station"
                        defaultMessage="This station currently has no data available. You can <anchor>look at another weather station</anchor>."
                        values={values}
                    />
                </Muted>
            </Shim>
        )
    }

    return (
        <Tabs theme="LOOSE">
            <HeaderSet>
                <Header>
                    <FormattedMessage
                        description="Component weather/station/Station"
                        defaultMessage="Table"
                    />
                </Header>
                <Header>
                    <FormattedMessage
                        description="Component weather/station/Station"
                        defaultMessage="Charts"
                    />
                </Header>
            </HeaderSet>
            <PanelSet>
                <Panel>
                    <Table measurements={measurements} columns={columns} headers={headers} />
                </Panel>
                <Panel>
                    <Bundle>
                        <ChartSet measurements={measurements} />
                    </Bundle>
                </Panel>
            </PanelSet>
        </Tabs>
    )
}

// Utils
function useHeaders() {
    return useIntlMemo(intl => [
        [
            Headers.createSnow(intl),
            Headers.createAirTemperature(intl),
            Headers.createWind(intl),
            Headers.createRelativeHumidity(intl),
        ],
        [
            Headers.createSnowHeight(intl),
            Headers.createNewSnow(intl),
            Headers.createAirTemperatureAvg(intl),
            Headers.createWindSpeedAvg(intl),
            Headers.createWindDirectionAvg(intl),
            Headers.createWindSpeedGust(intl),
        ],
    ])
}
function useColumns() {
    return useIntlMemo(intl => [
        Columns.createHour(intl),
        Columns.createSnowHeight(intl),
        Columns.createNewSnow(intl),
        Columns.createAirTemperatureAvg(intl),
        Columns.createWindSpeedAvg(intl),
        Columns.createWindDirectionAvg(intl),
        Columns.createWindSpeedGust(intl),
        Columns.createRelativeHumidity(intl),
    ])
}
